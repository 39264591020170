import { useEffect } from 'react';

import { type VideoAssetQueryResult, useVideoAssetLazyQuery } from '../../generated';

export type VideoAsset = NonNullable<VideoAssetQueryResult['data']>['videoAsset'];

export const usePollingVideoAsset = (requestedVideoAssetId: string | null | undefined, initialAsset?: VideoAsset) => {
  const [getVideoAsset, { data, stopPolling }] = useVideoAssetLazyQuery({
    // We are waiting for the video to be ready, that's why we need to hit the server
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  useEffect(() => {
    return () => stopPolling();
  }, [stopPolling]);

  useEffect(() => {
    if (requestedVideoAssetId) getVideoAsset({ variables: { id: requestedVideoAssetId } });
  }, [requestedVideoAssetId, getVideoAsset]);

  useEffect(() => {
    if (data?.videoAsset?.status === 'Ready') stopPolling();
  }, [data?.videoAsset?.status, stopPolling]);

  return requestedVideoAssetId ? data?.videoAsset ?? initialAsset : null;
};
