import type { FormikValues } from 'formik';
import { type FC, useCallback } from 'react';
import * as Yup from 'yup';

import { Form, FormSubmitButton, FormTextField, GridContainer, GridItem, styled } from '@cofenster/web-components';

import { useSigninActorByCode } from '../../../api/hooks/actor/useSigninActorByCode';
import { useNavigateTo } from '../../../hooks/useNavigateTo';

type Values = {
  code: string;
};

const STAGE = process.env.STAGE;
const initialValues = { code: '' };

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  code: Yup.string().trim().required('i18n.SigninByCodePage.SigninByCodeForm.code.required'),
});

const StyledFormTextField = styled(FormTextField)(() => ({
  input: {
    textTransform: 'uppercase',
  },
}));

export const SigninByCodeForm: FC = () => {
  const navigateTo = useNavigateTo();
  const signinActorByCode = useSigninActorByCode();
  const onSubmit = useCallback(
    async (values: FormikValues) => {
      const { code } = values as Values;
      try {
        const result = await signinActorByCode(code.replace(/\s/g, ''));
        const token = result.data?.signinActorByCode.token;
        navigateTo('welcome', { replace: true, params: { token } });
      } catch (error) {
        if (STAGE !== 'production') console.error(error);

        // biome-ignore lint/suspicious/noExplicitAny: safe with the ?.
        if ((error as any)?.extensions?.code === 'NOT_FOUND') {
          throw new Error('i18n.SigninByCodePage.SigninByCodeForm.code.invalid');
        }

        throw new Error('i18n.SigninByCodePage.SigninByCodeForm.error');
      }
    },
    [signinActorByCode, navigateTo]
  );

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12}>
          <StyledFormTextField
            id="code"
            name="code"
            label="i18n.SigninByCodePage.SigninByCodeForm.code.label"
            autoCapitalize="characters"
            fullWidth
            data-testid="signin-code-input"
          />
        </GridItem>

        <GridItem xs={12}>
          <FormSubmitButton fullWidth data-testid="signin-code-button">
            i18n.SigninByCodePage.SigninByCodeForm.submit
          </FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
